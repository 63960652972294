html {
  background: #fafafa;
  font-size: 14px;
  width: 100%;
  height: 100%;
}

.App {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.42857;
  text-rendering: optimizeLegibility;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}


.formInserimento {
  width: 30%;

  padding: 10px;
  margin: 0;
   position: absolute;
   top: 50%;
   -ms-transform: translateY(-50%);
   transform: translateY(-50%);
}

.logo {
  width: 200px;
  margin: 10px;
}
