.header {
  background-color: #222221;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.logoHeader {
  max-width: 100px;
  width: 200px;
}

.menu{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.vociMenu {
  padding: 5px;
  margin: 7px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;

}
